export const ApiConstants = {
    BASE: process.env.REACT_APP_BASE_URL,
    IAM: {
        SIGN_IN: 'iam/auth/sign-in/web/sign-in',
        SIGN_UP: 'iam/auth/sign-up/web',
        GET_FORGOT_PASSWORD_CODE: 'iam/auth/password/forgot',
        RESET_PASSWORD: 'iam/auth/password/confirm',
        MFA: 'iam/auth/sign-in/web/mfa-signin',
        CHECK_EXISTING_USER: 'iam/auth/user',
        RESEND_EMAIL_VERIFICATION: "iam/auth/email/regenerate?email=",
        CHECK_VERIFICATION:"iam/auth/email/verification/"
    },
    GENERATE_GOOGLE_STATE: "state/public/generate"
}
