import img_invalid_link from '../../Assets/invalid_invite_link.png';
import {
    DKLabel,
    DKButton
} from "deskera-ui-library";

export default function InvalidLink(props: any) {

    const getImage = () => <div style={{width: "100%", display: "flex", justifyContent:"center", textAlign:"center"}}>
        <img alt="Invalid Invite Link" src={img_invalid_link} className="ml-l invalid-link-image"/>
    </div>

    const getContent = () => <div className="column display-box">
            <DKLabel text="Sorry, the invite link has expired or not present" style={{display: "flex", width: "100%", justifyContent:"center", textAlign:"center"}} className="fw-m fs-xxl mt-xl mb-m" />
            <DKLabel
                    text={"To join the organisation, please check with your adminstrator or the person who shared to reinvite you."}
                    style={{display: "flex", width: "100%", justifyContent:"center", textAlign: "center"}}
                    className="mt-m"
            />
            <div style={{display:"flex", width: "100%", justifyContent: "center", flexWrap: "wrap"}} className="mt-xl">
                <DKLabel
                    text="In the meantime, "
                />
                <DKButton
                    title={'explore our features on your website.'}
                    className="text-red"
                    style={{justifyContent: "end", paddingLeft:"0px", paddingTop:"0px", fontSize: "13px"}}
                    onClick={() => {
                        window.open("https://www.deskera.com/sg/deskera-pricing", '_self')
                    }}
                />
            </div>
        </div>

    return (
        <div className="cards-main-holder column align-items-center" style={{display: "flex", justifyContent:"center", height: "100%"}}>
            <div className="card-background border-radius-s shadow-m display-box align-items-center position-relative invalid-link-div">
                {getImage()}
                {getContent()}
            </div>
        </div>
    )
}