import axios from 'axios';
import { ApiConstants } from '../Constants/ApiConstants';
import ApiManager from '../Managers/ApiManager';
import {
    showAlert
} from "deskera-ui-library";

const SERVER_ERROR_TEXT = 'Network Error';

const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: ApiConstants.BASE
});

const requestInterceptSuccess = (config) => {
    return config;
};

const requestInterceptError = (error) => {
    return Promise.reject(error);
};

const responseInterceptSuccess = (response) => {
    ApiManager.didSessionExpired = false;
    if (response.data && response.data.code && response.data.errorMessage) {
      showAlert("Error occurred!", response.data.errorMessage);
      return Promise.reject(response.data);
    }
    return response.data;
};

const responseInterceptError = (error) => {
    if (error.message === SERVER_ERROR_TEXT) {
        let msg = "There was some problem with the server. Please try again later.";
        return Promise.reject(msg);
    } else {
        if(error.response.data){
            if(error.response.data.errorMessage) {
                return Promise.reject(error.response.data.errorMessage);
            }
        }
    }
    return Promise.reject(error);
}

axiosInstance.interceptors.request.use(
    (response) => requestInterceptSuccess(response),
    (error) => requestInterceptError(error)
);
  
axiosInstance.interceptors.response.use(
    (response) => responseInterceptSuccess(response),
    (error) => responseInterceptError(error)
);

export default axiosInstance;
