export default class Utility {

    static getIndustryTypes() {
        return [
            {
                id: 1,
                name: "IT Service"
            },
            {
                id: 2,
                name: "Automobile"
            },
            {
                id: 3,
                name: "Textiles"
            },
            {
                id: 4,
                name: "Health"
            },
            {
                id: 5,
                name: "Real Estate"
            },
            {
                id: 6,
                name: "Others"
            }
        ]
    }

    static getManageType() {
        return [
            {
                id: 1,
                name: "Organized sales information"
            },
            {
                id: 2,
                name: "Track Individual performance"
            },
            {
                id: 3,
                name: "Improve won deal rate"
            },
            {
                id: 4,
                name: "Predict future sales"
            },
            {
                id: 5,
                name: "Manage customers and potential customers"
            },
            {
                id: 5,
                name: "Others"
            }
        ]
    }

}

export function isValidEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function isPasswordLengthValid(pwd) {
    return pwd.length >= 8 && pwd.length<= 72 ? true : false
}
