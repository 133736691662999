import { ApiConstants } from '../Constants/ApiConstants';
import http from './Interceptor';

export default class SignUp {
    static checkIfUserExist(email, contact) {
        let url = ApiConstants.IAM.CHECK_EXISTING_USER + "?contact="+contact+"&emailId="+email+"&action=checkExistingUserDetails"
        return http.get(url).then(
            (res) => {
                return Promise.resolve(res);
            },
            (err) => {
                return Promise.reject(err);
            }
        )
    }

    static resendEmailVerification(email) {
        return http.get(ApiConstants.IAM.RESEND_EMAIL_VERIFICATION + email).then(
            (res) => {
                return Promise.resolve(res);
            },
            (err) => {
                return Promise.reject(err);
            }
        )
    }

    static checkLink(user, token) {
        let url = ApiConstants.IAM.CHECK_VERIFICATION + "?user="+user+"&token="+token;
        return http.get(url).then(
            (res) => {
                return Promise.resolve(res);
            },
            (err) => {
                return Promise.reject(err);
            }
        )
    }
}