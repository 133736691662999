import {
    DKLabel,
    DKInput,
    INPUT_TYPE,
    DKCheckMark,
    DKButton
} from "deskera-ui-library";
import { useEffect, useState } from "react";
import { isPasswordLengthValid } from "../../Utility/Utility";

export default function AccountDetailsForm(props: any) {

    const [formValues, setFormValues] = useState({
        name: "",
        password: "",
        compName: "",
        agreeToTerms: false
    });
    const [isNextTapped, setIsNextTapped] = useState(false);
    const [validatePwd, setValidatePwd] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        if (formValues.name.trim().length > 0 && formValues.password.trim().length > 0 && formValues.compName.trim().length > 0 && formValues.agreeToTerms === true) {
            setIsFormValid(true)
        } else {
            setIsFormValid(false)
        }
    }, [formValues])

    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////

    const getHeader = () => <div className="column display-box">
        <DKLabel text= "Fill your account details" className="fw-m fs-xxl mt-xl mb-m" />
        <DKLabel text="Help us to get to know you" className="fw-b fs-m mt-xl mb-m" />
    </div>

    const getForm = () => <div className="column display-box">
        <DKInput
            name="name"
            placeholder="Name*"
            value={formValues.name}
            type={INPUT_TYPE.INPUT}
            onChange={(value: any) => {
                setFormValues({...formValues, name: value})}
            }
            canValidate={isNextTapped}
            required={true}
            errorMessage="Please enter a valid name"
            className="mt-s mb-s"
        />
        <DKInput
            name="password"
            placeholder="Password *"
            value={formValues.password}
            type={INPUT_TYPE.PASSWORD}
            onChange={(value: any) => {
                setFormValues({...formValues, password: value})}
            }
            canValidate={isNextTapped}
            required={true}
            errorMessage={validatePwd ? "Password length must be a min 8 characters and max 72 characters" : "Please enter a valid password"}
            className="mt-m mb-m"
            validator={(text) => {
                if(!isPasswordLengthValid(text) && text.length>0){
                    setValidatePwd(true);
                } else {
                    setValidatePwd(false);
                }
                return ;    
            }}
        />
        <DKInput
            name="compName"
            placeholder="Company Name*"
            value={formValues.compName}
            type={INPUT_TYPE.INPUT}
            onChange={(value: any) => {
                setFormValues({...formValues, compName: value})}
            }
            canValidate={isNextTapped}
            required={true}
            errorMessage="Please enter a valid company name"
            className="mt-s mb-s"
        />
        <div style={{display: "flex", flexWrap: "wrap"}} className="mt-s">
            <DKCheckMark
                title="I agree to the "
                isSelected={formValues.agreeToTerms}
                color="bg-blue"
                onClick={() => {
                    setFormValues({...formValues, agreeToTerms: !formValues.agreeToTerms})
                }}
                className="pt-xs"
            />
            <DKButton
                title={'Terms of Use'}
                className="fw-h fs-m text-red"
                style={{padding: "0px", fontSize:"13px", marginTop: "2px"}}
                onClick={()=> window.open("https://www.deskera.com/terms-of-service", "_blank")}
            />
            <DKLabel text=" and " className="mt-xs"/>
            <DKButton
                title={'Privacy Policy'}
                className="fw-h fs-m text-red"
                style={{padding: "0px", fontSize:"13px", marginTop: "2px"}}
                onClick={()=> window.open("https://www.deskera.com/privacy-policy", "_blank")}
            />
        </div>
        <DKButton
            title="Next"
            className="fs-m fw-m mr-r mt-l pt-m pb-m text-white"
            style={{backgroundColor: isFormValid ? "black" : "grey", width: "100%", justifyContent: "center"}}
            onClick={() => {
                checkIsFormValid()
            }}
            disabled={!isFormValid}
        />
    </div>

    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////

    function checkIsFormValid() {
        setIsNextTapped(true);
        if (isPasswordLengthValid(formValues.password)) {
            props.nextTapped(formValues)
        } else {
            return;
        }
    }

    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////

    return (
        <div>
            {getHeader()}
            {getForm()}
        </div>
    )

}