import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import './css/Common.css'
import { COLORS } from './Constants/Color.js';
import img_left_bottom_bg from './Assets/signup_corner-triangle@2x.png';
import img_right_bottom_bg from './Assets/signup_illustration@2x.png';
import img_left_top_bg from './Assets/deskera-logo-dark@2x.png';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { PAGE_ROUTES } from './Managers/RouteManager';

import SignInForm from './Components/SignIn/SignInForm';
import ForgotPasswordForm from './Components/ForgotPassword/ForgotPasswordForm';
import MfaForm from './Components/MFA/MfaForm';
import SignUpForm from './Components/SignUp/SignUpForm';
import VerifyEmailAddress from './Components/Verification/VeriifyEmailAddress';
import InvalidLink from './Components/InvalidLink/InvalidLink';
import SetPasswordForm from './Components/SetPassword/SetPasswordForm';

import MixpanelService from './Managers/MixPanelManager';

function App() {

  useEffect(() => {
    MixpanelService.init();
  }, [])

  const getTopLeftBgImage = () => {
    return <img className="left-top-bg" alt="Deskera" src={img_left_top_bg} />;
  }

  const getLeftBottomBGImage = () => {
    return <img className="left-bottom-bg" alt="Deskera" src={img_left_bottom_bg} />;
  }

  const getRightBottomBGImage = () => {
    return <img className="right-bottom-bg" alt="Deskera" src={img_right_bottom_bg} />;
  }

  return (
    <div 
      style={{
        backgroundColor: COLORS.deskera,
        height: '100%',
        width: '100%',
        position: 'fixed',
      }}
    >
      {getTopLeftBgImage()}
      {getLeftBottomBGImage()}
      {getRightBottomBGImage()}
      <Router>
        <Switch>
          <Route exact path={PAGE_ROUTES.HOME} component={SignInForm} />

          {/* Sign In */}
          <Route exact path={PAGE_ROUTES.SIGN_IN}component={SignInForm} />

          {/* Forgot Password */}
          <Route exact path={PAGE_ROUTES.FORGOT_PASSWORD} component={ForgotPasswordForm} />

          {/* MFA */}
          <Route exact path={PAGE_ROUTES.MFA} component={MfaForm} />

          {/* Sign Up */}
          <Route path={[PAGE_ROUTES.SIGN_UP, PAGE_ROUTES.USER_PARTNER_SIGN_UP]} component={SignUpForm} />

          {/* Email Verification */}
          <Route path={PAGE_ROUTES.EMAIL_VERIFICATION} component={VerifyEmailAddress}/>

          {/* Invalid Link */}
          <Route path={PAGE_ROUTES.INVALID_LINK} component={InvalidLink}/>
          
          {/* Set Password */}
          <Route path={PAGE_ROUTES.SET_PASSWORD} component={SetPasswordForm}/>

        </Switch>
      </Router>
    </div>
  );
}

export default App;
