import mixpanel from 'mixpanel-browser';

export const MIXPANEL_EVENT_NAMES = {
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
    
};

export default class MixpanelService {
    static init = () => mixpanel.init(process.env.REACT_APP_APP_MIXPANEL_KEY as string);

    static eventTrack(action, properties) {
        try {
            mixpanel.track(action, properties);
        } catch (e) {
            if (!(e instanceof ReferenceError)) {
                throw e;
            }
        }
    }

    static alias(userId, email) {
        mixpanel.alias(userId, email);
    }

    static track(id, action) {
        mixpanel.track(id, action);
    }

    static pageTrack(path) {
        try {
            mixpanel.track('Page Viewed', { page: path });
        } catch (e) {
            if (!(e instanceof ReferenceError)) {
                throw e;
            }
        }
    }

    static setUsername(userId) {
        try {
            mixpanel.identify(userId);
        } catch (e) {
            if (!(e instanceof ReferenceError)) {
                throw e;
            }
        }
    }

}
