import { useEffect, useState } from 'react';
import '../../css/SignIn.css';
import DeskeraLogo, {DESKERA_LOGO_COLOR} from '../DeskeraLogo/DeskeraLogo';
import googleLogo from '../../Assets/ic_google.png';
import appleLogo from '../../Assets/apple-logo.png';
import ic_error from '../../Assets/ic_error.png';

import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';

import {
    DKLabel,
    DKInput,
    INPUT_TYPE,
    DKButton,
    DKCheckMark,
    DKIcon
} from 'deskera-ui-library';
import { useHistory, withRouter } from 'react-router-dom';
import { isValidEmail, isPasswordLengthValid } from '../../Utility/Utility';

import ReactRecaptcha3 from 'react-google-recaptcha3';
import ApiManager from '../../Managers/ApiManager';
import MixpanelService from '../../Managers/MixPanelManager';
import { MIXPANEL_EVENT_NAMES } from '../../Managers/MixPanelManager';

export default function SignInForm(props: any) {

    const history = useHistory()

    const [isLoginTapped, setIsLoginTapped] = useState(false);
    const [formValues, setFormValues] = useState({
        email:localStorage.getItem("rememberMe") ? localStorage.getItem("user") : "" as any,
        password: "",
        rememberMe: false
    })
    const [redirectURL, setRedirectURL] = useState<any>();
    const [errorMsg, setErrorMsg] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        RouteManager.setPresenter({props});

        const authResult = new URLSearchParams(window.location.search); 
        const redirect = authResult.get('redirectUrl') !== null ? authResult.get('redirectUrl') : process.env.REACT_APP_URL_ERP;

        if(redirect !== null && redirect !== undefined) {
            localStorage.setItem('redirectUrl',redirect.toString());
            setRedirectURL(redirect)
        } else {
            setRedirectURL(process.env.REACT_APP_ALL_IN_ONE)
        }

        ReactRecaptcha3.init(process.env.REACT_APP_reCAPTCHA_SITE_KEY).then(
            (status) => {
              console.log(status);
            }
        );
    },[])

    useEffect(() => {
        localStorage.setItem('rememberMe', formValues.rememberMe.toString());
        localStorage.setItem('user', formValues.rememberMe ? formValues.email : "");
    },[formValues.rememberMe])

    ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////     Google     ////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    const getGoogleLogo = () => (
        <div className='parent-width'>
            <DKButton
                title="Sign in with Google"
                icon={googleLogo}
                className="border-m bg-white justify-content-center"
                onClick={()=>getGoogleState()}
            />
        </div>
    )

    function getGoogleState(){
        ApiManager.getGoogleState(
            redirectURL,
            null,
            (data) => {
                getGoogleLogin(data.state);
            },
            (error) => {
                setErrorMsg("Please try again.");
            }
        );

    }

    function getGoogleLogin(state) {
        let url = process.env.REACT_APP_GOOGLE_URL + state + "&access_type=offline&include_granted_scopes=true&promt=consent";
        window.open(url, '_self');
    }


    ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////     Apple     /////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    const getAppleLogo = () => (
        <div className='parent-width'>
            <DKButton
                title="Sign in with Apple"
                icon={appleLogo}
                className="border-m bg-white justify-content-center mt-m"
                onClick={()=>getAppleState()}
            />
        </div>
    )

    function getAppleState() {
        let state = {
            appCode: "GO",
            randomId: Math.random(),
            redirectUrl: redirectURL
          };
        //   document.location.href = process.env(
        //     btoa(JSON.stringify(state))
        //   );
        getAppleLogin(btoa(JSON.stringify(state)))
    }

    function getAppleLogin(state) {
        let url = process.env.REACT_APP_APPLE_URL + state + "&scope=name%20email&response_mode=form_post&usePopup=true";
        window.open(url, '_self');
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////   Normal SignIn   ///////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    function signIn(){
        let userDetails = {
            userName: formValues.email,
            password: formValues.password
        }
        setIsLoginTapped(true);
        if(isValidEmail(formValues.email) && isPasswordLengthValid(formValues.password)){
            ReactRecaptcha3.getToken().then(
                (token) =>{
                    ApiManager.signIn(
                        userDetails,
                        token,
                        (data) => {
                            let mixpanelRequest = {userName: formValues.email, password: ""}
                            MixpanelService.alias(formValues.email, formValues.password);
                            MixpanelService.setUsername(formValues.email);
                            MixpanelService.eventTrack(MIXPANEL_EVENT_NAMES.LOGIN_SUCCESS, mixpanelRequest);
                            if(data.mfaenabled) {
                                let data = {userName: formValues.email, password: formValues.password}
                                setLoading(false);
                                history.push({
                                    pathname: PAGE_ROUTES.MFA,
                                    state: data
                                })
                            } else {
                                setLoading(false);
                                window.open(
                                    redirectURL,
                                    "_self"
                                );
                            }
                        },
                        (error) => {
                            setLoading(false);
                            if(error.includes("User email is not verified.")){
                                let params = "mode=EMAILNOTVERIFIED&email="+encodeURIComponent(formValues.email)+"&name="+ "" +"&verified=false"
                                RouteManager.navigateToPage(PAGE_ROUTES.EMAIL_VERIFICATION, params)
                            } else {
                                setErrorMsg(error);
                            }
                        }
                    )
                }
            )
        } else {
            setLoading(false);
            return;
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////
    
    const getForm = () => {
        return (
            <div className="column display-box">
                <DKLabel text="Sign In" className="fw-m fs-xxl mt-xl mb-m" />
                <DKLabel text="User Credentials" className="fw-b fs-m mt-xl mb-m" />
                <DKInput
                    name="email"
                    placeholder="Email Address *"
                    value={formValues.email}
                    type={INPUT_TYPE.EMAIL}
                    onChange={(value: any) => {
                        setFormValues({...formValues, email: value})}
                    }
                    canValidate={isLoginTapped}
                    required={true}
                    errorMessage="Please enter a valid email"
                    className="mt-m mb-m"
                />
                <DKInput
                    name="password"
                    placeholder="Password *"
                    value={formValues.password}
                    type={INPUT_TYPE.PASSWORD}
                    onChange={(value: any) => {
                        setFormValues({...formValues, password: value})}
                    }
                    canValidate={isLoginTapped}
                    required={true}
                    errorMessage={"Password length must be a min 8 characters and max 72 characters"}
                    className="mt-m mb-l"
                    icon={appleLogo}
                />
                <div style={{width: "100%"}} className="mb-m">
                    {errorMsg.trim().length>0 && 
                        <div style={{display: "flex"}}>
                            <DKIcon src={ic_error} style={{height: "19px"}}/>
                            <div className={`text-red text-wrap-none fw-m pl-s`} style={{whiteSpace: "break-spaces", fontSize: "12px", paddingTop:"2px"}}>
                                {errorMsg}
                            </div>
                        </div>
                    }
                </div>
                <div style={{width: "100%", display: "flex"}} className="justify-content-between">
                    <DKCheckMark
                        title="Remember Me"
                        isSelected={formValues.rememberMe}
                        color="bg-blue"
                        onClick={() => {
                            setFormValues({...formValues, rememberMe: !formValues.rememberMe})
                        }}
                        className=" pt-s"
                    />
                    <DKButton
                        title={'Forgot Password?'}
                        className="fs-m"
                        style={{justifyContent: "end", paddingRight:"0px"}}
                        onClick={() => {
                            MixpanelService.pageTrack("forgotpassword");
                            RouteManager.navigateToPage(PAGE_ROUTES.FORGOT_PASSWORD, null);
                        }}
                    />
                </div>
                <DKButton
                    title={loading ? "Please wait" : "Continue"}
                    className="fs-m fw-m mr-r mt-m pt-m pb-m text-white"
                    style={{backgroundColor:"black", width: "100%", justifyContent: "center"}}
                    onClick={() => {
                        setLoading(true);
                        signIn();
                    }}
                    disabled={loading ? true: false}
                />
                <DKLabel text="OR" className="fs-m mt-m mb-m flex-width" style={{width: "100%", textAlign: "center"}} />
                {getGoogleLogo()}
                {getAppleLogo()}
                <div style={{display:"flex", width: "100%"}} className="mt-m justify-content-center">
                    <DKLabel text="New to Deskera?" className="fs-m mt-m mb-m" />
                    <DKButton
                        title={'Sign-up'}
                        className="fw-h fs-m text-red"
                        style={{justifyContent: "end", paddingLeft:"3px"}}
                        onClick={() => {
                            MixpanelService.pageTrack("signup");
                            RouteManager.navigateToPage(PAGE_ROUTES.SIGN_UP, null)
                        }}
                    />
                </div>
            </div>
        )
    }

    return (
        <div className="cards-main-holder column align-items-center" style={{display: "flex", justifyContent:"center", height: "100%"}}>
            <div className="card-background card-div border-radius-s shadow-m display-box align-items-center position-relative">
                {getForm()}
            </div>
        </div>
    )
}