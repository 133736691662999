import {
    DKLabel,
    DKInput,
    INPUT_TYPE,
    DKButton
} from "deskera-ui-library";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import RouteManager, { PAGE_ROUTES } from "../../Managers/RouteManager";
import ApiManager from "../../Managers/ApiManager";

interface IState {
    userName?: string;
    password?: string;
  }

export default function MfaForm(props: any) {

    const location = useLocation();
    const userName = (location.state as IState).userName;
    const password = (location.state as IState).password;

    const [isContinueTapped, setIsContinueTapped] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [formValues, setFormValues] = useState({
        userName: userName,
        password: password,
        mfaCode: ""
    });
    const [redirectURL, setRedirectURL] = useState<any>(localStorage.getItem('redirectUrl'));

    const getForm = () => <div className="column display-box">
        <DKLabel text="2FA" className="fw-m fs-xxl mt-xl mb-m" />
        <DKLabel text="Enter Code" className="fw-b fs-m mt-xl mb-m" />
        <DKInput
            name="mfaCode"
            placeholder="OTP Code *"
            value={formValues.mfaCode}
            type={INPUT_TYPE.TEXT}
            onChange={(value: any) => {
                setFormValues({...formValues, mfaCode: value})
            }}
            canValidate={isContinueTapped}
            required={true}
            errorMessage="Please try again"
            className="mt-m mb-m"
        />
        <DKLabel 
            text="Enter the 6 digit code provided by the authenticator app. If you have lost your device, enter your recovery code or contact your organisation administrator." 
            className="mt-xxs text-gray mb-m" 
            style={{fontSize: "11px"}}
        />
        {errorMsg.trim().length>0 && 
            <div className={`text-red border-red p-v-s pl-m border-radius-s text-wrap-none`} style={{whiteSpace: "break-spaces"}}>
                {errorMsg}
            </div>
        }
        <DKButton
            title="Submit"
            className="fs-m fw-m mr-r mt-l pt-m pb-m text-white"
            style={{backgroundColor:"black", width: "100%", justifyContent: "center"}}
            onClick={() => {
            //   showAlert("Hellow");
                verifiedMFA()
            }}
        />
        <DKButton
            title="Sign In"
            style={{width: "100%"}}
            className="mt-m border-m bg-white justify-content-center"
            onClick={() => {
                RouteManager.navigateToPage(PAGE_ROUTES.SIGN_IN, null)
            }}
        />
    </div>

    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////

    function verifiedMFA() {
        ApiManager.mfa(
            formValues,
            null,
            (data) => {
                setErrorMsg("");
                window.open(
                    redirectURL,
                    "_self"
                );
            },
            (error) => {
                setErrorMsg(error);
            }
        )
    }

    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////

    return (
        <div className="cards-main-holder column align-items-center" style={{display: "flex", justifyContent:"center", height: "100%"}}>
            <div className="card-background card-div border-radius-s shadow-m display-box align-items-center position-relative">
                {getForm()}
            </div>
        </div>
    )
}