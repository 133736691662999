import {
    DKLabel,
    DKInput,
    INPUT_TYPE,
    DKButton,
    DKIcon
} from "deskera-ui-library";
import { useState, useEffect } from "react";
import RouteManager, { PAGE_ROUTES } from "../../Managers/RouteManager";
import ResetPassword from "../../Services/ResetPassword";

import { isPasswordLengthValid, isValidEmail } from "../../Utility/Utility";
import ic_error from '../../Assets/ic_error.png';

import MixpanelService from "../../Managers/MixPanelManager";

export default function ForgotPasswordForm(props: any) {

    useEffect(() => {
        RouteManager.setPresenter({props});
    },[])

    const [email, setemail] = useState("");
    const [isSubmitTapped, setIsSubmitTapped] = useState(false);
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [isResetTapped, setIsResetTapped] = useState(false);
    const [resetAttempt, setResetAttempt] = useState(3);
    const [formValues, setFormValues] = useState({
        password: "",
        cPassword: "",
        code: ""
    });
    const [errorMsg, setErrorMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const [validatePwd, setValidatePwd] = useState(false);
    const [validateCPwd, setValidateCPwd] = useState(false);
    const [isResetSuccessful, setIsResetSuccessful] = useState(false);

    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////

    const getForm = () => <div>
        {getHeader()}
            {!isCodeSent && getEmailForm()}
            {isCodeSent && resetPasswordForm()}
            {errorMsg.length > 0 && 
                <div style={{display: "flex"}} className="mt-s">
                    <DKIcon src={ic_error} style={{height: "19px"}}/>
                    <div className={`text-red text-wrap-none fw-m pl-s`} style={{whiteSpace: "break-spaces", fontSize: "12px", paddingTop:"2px"}}>
                        {errorMsg}
                    </div>
                </div>
            }
            {getFooter()}
    </div>

    const getHeader = () => <div className="column display-box">
        <DKLabel text="Reset Password" className="fw-m fs-xxl mt-xl mb-m" />
        <DKLabel text="User Credentials" className="fw-b fs-m mt-xl mb-m" />
    </div>

    const getEmailForm = () => <div className="column display-box">
        <DKInput
            name="email"
            placeholder="Email Address *"
            value={email}
            type={INPUT_TYPE.EMAIL}
            onChange={(value: any) => {
                setemail(value)
            }}
            canValidate={isSubmitTapped}
            required={true}
            errorMessage="Please enter a valid email"
            className="mt-m mb-s"
        />
    </div>

    const resetPasswordForm = () => <div className="column display-box">
        <DKInput
            name="code"
            placeholder="Verification Code *"
            value={formValues.code}
            type={INPUT_TYPE.TEXT}
            onChange={(value: any) => {
                setFormValues({...formValues, code: value})
            }}
            canValidate={isResetTapped}
            required={true}
            errorMessage="Please try again"
            className="mt-m mb-s"
        />
        <DKInput
            name="password"
            placeholder="New Password (min 8 character)*"
            value={formValues.password}
            type={INPUT_TYPE.PASSWORD}
            onChange={(value: any) => {
                setFormValues({...formValues, password: value})}
            }
            canValidate={isResetTapped}
            required={true}
            errorMessage={validatePwd ? "Password length must be a min 8 characters and max 72 characters" : "Please enter a valid password"}
            className="mt-m mb-s"
            validator={(text) => {
                if(!isPasswordLengthValid(text) && text.length>0){
                    setValidatePwd(true);
                } else {
                    setValidatePwd(false);
                }
                return isPasswordLengthValid(text);   
            }}
        />
        <DKInput
            name="cPassword"
            placeholder="Confirm Password *"
            value={formValues.cPassword}
            type={INPUT_TYPE.PASSWORD}
            onChange={(value: any) => {
                setFormValues({...formValues, cPassword: value})}
            }
            canValidate={isResetTapped}
            required={true}
            errorMessage={validateCPwd ? "Password length must be a min 8 characters and max 72 characters" : "Please enter a valid password"}
            className="mt-m mb-l"
            validator={(text) => {
                if(!isPasswordLengthValid(text) && text.length>0){
                    setValidateCPwd(true);
                } else {
                    setValidateCPwd(false);
                }
                return isPasswordLengthValid(text);   
            }}
        />
        <div style={{display: "flex"}} className="mt-m">
            <DKLabel 
                text="You have " 
                className="fs-m mb-m" 
            />
            <DKLabel text={resetAttempt + " attempts"} className="fs-m mb-s text-red" />
            <DKLabel text=" left." className="fs-m mb-s" />
        </div>
        <div style={{display: "flex"}}>
        </div>
    </div>

    const getFooter = () => <div>
        <div style={{width: "100%", display: "flex", justifyContent: "space-between"}} className="mt-l ">
            <DKButton
                title={isCodeSent ? "Cancel" : "Back"}
                style={{width: "49%"}}
                className="border-m bg-white justify-content-center"
                onClick={() => {
                    RouteManager.navigateToPage(PAGE_ROUTES.SIGN_IN, null)
                }}
            />
            <DKButton
                title="Submit"
                className="fs-m fw-m text-white"
                style={{backgroundColor:"black", width: "49%", justifyContent: "center"}}
                onClick={() => {
                    setLoading(true);
                    if(isCodeSent) {
                        setIsResetTapped(true);
                        setResetPassword();
                    } else  {
                        setIsSubmitTapped(true);
                        getCode();
                    }
                }}
            />
        </div>
        <div style={{display:"flex", width: "100%"}} className="mt-m justify-content-center">
            <DKLabel text="New to Deskera?" className="fs-m mt-m mb-m" />
            <DKButton
                title={'Sign-up'}
                className="fw-h fs-m text-red"
                style={{justifyContent: "end", paddingLeft:"3px"}}
                onClick={() => {
                    MixpanelService.pageTrack("signup");
                    RouteManager.navigateToPage(PAGE_ROUTES.SIGN_UP, null);
                }}
            />
        </div>
    </div>

    const successMSg = () => <div className="column display-box">
        <DKLabel text="Confirm Password Successful" className="fw-m fs-xxl mt-xl mb-m" style={{width:"100%", textAlign: "center"}}/>
        <DKLabel text="Password reset successful!" className="fs-m mt-m mb-m" style={{width:"100%", textAlign: "center"}}/>
        <DKButton
            title="Back to Login"
            className="fs-m fw-m mr-r mt-m pt-m pb-m text-white"
            style={{backgroundColor:"black", width: "100%", justifyContent: "center"}}
            onClick={() => {
                RouteManager.navigateToPage(PAGE_ROUTES.SIGN_IN, null);
            }}
        />
    </div>

    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////

    function getCode() {
        let data = {userName: email}
        if (isValidEmail(email)) {
            ResetPassword.getCode(data)
                .then((res) => {
                    setErrorMsg("");
                    setIsCodeSent(true);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    setErrorMsg(err);
                })
        }
    }

    function checkFormValid() {

        if(formValues.password !== formValues.cPassword) {
            setErrorMsg("New Password and Confirm Password is different.")
            return false;
        } else{
            setErrorMsg("");
            return true
        }
    }

    function setResetPassword() {
        if(checkFormValid()) {
            let data = {
                confirmationCode: formValues.code,
                password: formValues.password,
                userName: email
            }
            ResetPassword.resetPassword(data)
                .then((res: any) => {
                    setResetAttempt( res?.retriesLeft === -1 ? 0 : res?.retriesLeft)
                    setErrorMsg(res?.message)
                    if(res?.retriesLeft === -1) {
                        setIsResetSuccessful(true);
                    }
                })
                .catch((err) => {
                    console.log("err", err)
                })
        }
        
    }

    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////

    return (
        <div className="cards-main-holder column align-items-center" style={{display: "flex", justifyContent:"center", height: "100%"}}>
            <div className="card-background card-div border-radius-s shadow-m display-box align-items-center position-relative">
                {!isResetSuccessful && getForm()}
                {isResetSuccessful && successMSg()}
            </div>
        </div>
    )
}