export const PAGE_ROUTES = {
    HOME: "/",
    SIGN_IN: "/auth/signin",
    SIGN_UP: "/auth/signup",
    USER_PARTNER_SIGN_UP: "/auth/partner-signup",
    FORGOT_PASSWORD: "/forgot-password",
    MFA: "/2fa",
    EMAIL_VERIFICATION: "/auth/email/verification",
    INVALID_LINK: "/auth/invalid-link",
    SET_PASSWORD: "/auth/setpassword"
}

export default class RouteManager {
    static presenter: any = null;
    static setPresenter(presenter: any) {
        this.presenter = presenter;
        // IntercomManager.trackEvent("page", { url: window.location.pathname });
    }
    static navigateToPage(pageRoute: string, param:any) {
        RouteManager.presenter.props.history.push(
            pageRoute + (param ? "?" + param : "")
        );
        // AppManager.scrollToTop();
        // IntercomManager.trackEvent("page", { url: window.location.pathname });
    }
}