import { ApiConstants } from "../Constants/ApiConstants";

export default class ApiManager {
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////

    static didSessionExpired = false;

    static getApiRequestOptions(method: string, body: any, recaptchaToken: any) {
        var requestOptions:RequestInit = {
            method: method,
            credentials: "include",
            // withCredentials: true,
            // mode: "cors",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                // 'Access-Control-Allow-Origin': '*'
                // accept: "*/*"
            }
        };
        if(recaptchaToken !== null) {
            requestOptions.headers = {...requestOptions.headers, _csrf_token: recaptchaToken}
        }
        if (method === 'POST' || method === 'PUT') {
            // body = JSON.stringify(body);
            // requestOptions = { ...requestOptions, body };
            body = JSON.stringify(body);
            const newRequestOptions = requestOptions;
            requestOptions = { ...newRequestOptions, body };
        }
        return requestOptions;
    }
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////

    static signIn(userData: any, recaptchaToken, success: any, failed: any) {          
        fetch(ApiConstants.BASE + ApiConstants.IAM.SIGN_IN, ApiManager.getApiRequestOptions('POST',userData, recaptchaToken))
            .then((response) => response.json())
            .then((data) => {
                if (data.errorMessage) {
                    failed(data.errorMessage);
                } else {
                    success(data);
                }
            })
            .catch((error) => {
                failed('Oops, There is an unexpected error encountered. Please try again!');
            });
    }

    static signUp(data, recaptchaToken, success: any, failed: any){
        fetch(ApiConstants.BASE + ApiConstants.IAM.SIGN_UP, ApiManager.getApiRequestOptions('POST',data, recaptchaToken))
            .then((response) => response.json())
            .then((data) => {
                if (data.errorMessage) {
                    failed(data.errorMessage);
                } else {
                    success(data);
                }
            })
            .catch((error) => {
                failed('Oops, There is an unexpected error encountered. Please try again!');
            });
    }

    static getGoogleState(redirectURL, recaptchaToken, success, failed) {
        let url = ApiConstants.BASE + ApiConstants.GENERATE_GOOGLE_STATE + "?redirectUrl=" + redirectURL
        fetch(url, ApiManager.getApiRequestOptions('GET', null, recaptchaToken))
            .then((response) => response.json())
            .then((data) => {
                success(data);
            })
            .catch((error) => {
                failed("Try again");
            })
    }

    static getAppleState() {
        
    }

    static mfa(userDetails, recaptchaToken, success, failed) {
        fetch(ApiConstants.BASE + ApiConstants.IAM.MFA, ApiManager.getApiRequestOptions('POST', userDetails, recaptchaToken))
            .then((response) => response.json())
            .then((data) => {
                if (data.errorMessage) {
                    let errorMsg = "Please try again. If still unsuccessful, please contact your administrator.";
                    failed(errorMsg);
                } else {
                    success();
                }
            })
    }

}