import {
    DKLabel,
    DKButton,
    DKInput,
    INPUT_TYPE
} from "deskera-ui-library";
import { useEffect, useState } from "react";
import { ApiConstants } from "../../Constants/ApiConstants";
import RouteManager, { PAGE_ROUTES } from "../../Managers/RouteManager";
import SignUp from "../../Services/SignUp";

export default function VerifyEmailAddress(props: any) {

    const params = new URLSearchParams(window.location.search)

    const [email, setEmail] = useState<string | any>("")
    const [isVerifiedSuccessful, setIsVerifiedSuccessful] = useState(false);
    const [showThankYouMsg, setShowThankYouMsg] = useState(false);
    const [timeLeft, setTimeLeft] = useState<number | any>(5);
    const [isEmailResend, setIsEmailResend] = useState(false);
    const [resendTimeLeft, setResendTimeLeft] = useState<number | any>(10);
    const [invalidLink, setInvalidLink] = useState(false);
    const [invalidLinkEmail, setInvalidLinkEmail] = useState("");
    const [isResendEmailTapped, setIsResendEmailTapped] = useState(false);

    useEffect(() => {
        RouteManager.setPresenter({props});

        if (params.get('token')) {
            checkLinkIsValid();
        } else {
            if(params.get('verified') === "true"){
                setShowThankYouMsg(true);
            } else {
                setIsVerifiedSuccessful(false);
            }

        }
    },[])

    useEffect(() => {
        if(showThankYouMsg){
            if(timeLeft===0){
                window.open(process.env.REACT_APP_ALL_IN_ONE, '_self');
             }
             const intervalId = setInterval(() => {
         
               setTimeLeft(timeLeft - 1);
             }, 1000);
         
             return () => clearInterval(intervalId);
        }
    }, [showThankYouMsg, timeLeft]);

    useEffect(() => {
        if(isEmailResend) {
            if(resendTimeLeft===0){
                setIsEmailResend(false);
                setResendTimeLeft(60);
            }
            const intervalId = setInterval(() => {
         
                setResendTimeLeft(resendTimeLeft - 1);
            }, 1000);
         
            return () => clearInterval(intervalId);
        }
    }, [isEmailResend, resendTimeLeft])

    useEffect(() => {
        setEmail(invalidLinkEmail);
    }, [isResendEmailTapped])

    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////

    const getHeader = () => {
        let title;

        if(showThankYouMsg){
            title = "Thank you for signing up."
        } else {
            if(isVerifiedSuccessful){
                title = "Email verification successful"
            } else {
                if(invalidLink){
                    title = "Email verification failed"
                } else {
                    title = "Please verify your email address"
                }
            }
        }

        return (
            <div className="column display-box">
                <DKLabel text={title} style={{display: "flex", width: "100%", justifyContent:"center", textAlign:"center"}} className="fw-m fs-xxl mt-xl mb-m" />
            </div>
        )
    }

    const getBeforeVerifiedContent = () =>{
        let content;

        if(isEmailResend) {
            content = "Resend Email (" + resendTimeLeft+" sec)"
        } else {
            content = "Resend Email"
        }

        return (
            <div className="column display-box">
                <DKLabel
                    text={"Dear User, thanks for signing up."}
                    style={{display: "flex", width: "100%", justifyContent:"center"}}
                />
                <div style={{display:"flex", width: "100%", justifyContent: "center", flexWrap: "wrap"}}>
                    <DKLabel
                        text="We have sent an email to "
                    />
                    <DKLabel
                        text={email.length > 0 ? email : params.get('email')}
                        className="text-red"
                    />
                </div>
                <DKLabel
                    text={"Just click on the link in the email to complete your sign up. If you don’t see it, you may need to check your spam folder."}
                    className="mt-l mb-l"
                    style={{display: "flex", width: "100%", justifyContent:"center", textAlign:"center"}}
                />
                <DKLabel
                    text={"Still can’t find the email?"}
                    style={{display: "flex", width: "100%", justifyContent:"center"}}
                    className="mt-m"
                />
                <div style={{display:"flex", justifyContent: "center", width:"100%"}}>
                    <DKButton
                        title={content} 
                        className="fs-m fw-m mr-r mt-m pt-m pb-m text-white"
                        style={{backgroundColor:"black", width: "40%", justifyContent: "center", opacity: isEmailResend && "0.6"}}
                        onClick={() => {
                            resendEmail(params.get("email"));
                        }}
                        disabled={isEmailResend}
                    />
                </div>
            </div>
        )
    } 

    const getVerifiedContent = () => <div className="column display-box">
        <DKLabel
            text={"Your email has been confirmed and your account is verified."}
            style={{display: "flex", width: "100%", justifyContent:"center", textAlign: "center"}}
            className="mt-l mb-s"
        />
        <DKLabel
            text={"Please login to start using Deskera."}
            style={{display: "flex", width: "100%", justifyContent:"center", textAlign: "center"}}
            className="mt-s mb-l"
        />
        <DKButton
            title="Log in"
            className="fs-m fw-m mr-r mt-m pt-m pb-m text-white"
            style={{backgroundColor:"black", width: "100%", justifyContent: "center"}}
            onClick={() => {
                RouteManager.navigateToPage(PAGE_ROUTES.SIGN_IN, null);
            }}
        />
    </div>

    const getInvalidLinkContent = () => <div className="column display-box">
        <DKLabel
            text="Enter your email address and we would send you verification link again."
            style={{display: "flex", width: "100%", justifyContent:"center", textAlign:"center"}}
            className="mt-s mb-xl"
        />
        <DKInput
            name="email"
            placeholder="Email Address *"
            value={invalidLinkEmail}
            type={INPUT_TYPE.EMAIL}
            onChange={(value: any) => {
                setInvalidLinkEmail(value)
            }}
            canValidate={isResendEmailTapped}
            required={true}
            errorMessage="Please enter a valid email"
            className="mt-m mb-m"
        />
        <DKButton
            title="Resend Email"
            className="fs-m fw-m mr-r mt-m pt-m pb-m text-white"
            style={{backgroundColor:"black", width: "100%", justifyContent: "center", opacity: isEmailResend && "0.6"}}
            onClick={() => {
                resendEmail(invalidLinkEmail);
                setInvalidLink(false);
                setIsResendEmailTapped(true);
            }}
        />
    </div>

    const displayThankYouMsg = () => <div className="column display-box">
        <div style={{display:"flex", width: "100%", justifyContent: "center", flexWrap: "wrap"}}>
            <DKLabel
                text={"Dear " +params.get('name') + ", your sign up is complete. We have sent a verification email to "}
            />
            <DKLabel
                text={params.get('email') + " ."}
                className="text-blue"
            />
        </div>
        <DKLabel
            text={"Please check other folders, including spam if you do not find this verification email in your inbox."}
            style={{display: "flex", width: "100%", justifyContent:"center", textAlign:"center"}}
            className="mt-s mb-xl"
        />
        <DKLabel
            text={"You will be redirected to Deskera system in ("+timeLeft+") seconds."}
            style={{display: "flex", width: "100%", justifyContent:"center", textAlign:"center"}}
            className="mt-m mb-xl"
        />
        <DKButton
            title="Take Me There Now"
            className="fs-m fw-m mr-r mt-l pt-m pb-m text-white bg-red"
            style={{width: "100%", justifyContent: "center"}}
            onClick={() => {
                window.open(process.env.REACT_APP_ALL_IN_ONE, '_self');
            }}
        />
    </div>

    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////

    function resendEmail(emailAdd) {
        SignUp.resendEmailVerification(encodeURIComponent(emailAdd))
            .then((res:any) => {
                setIsEmailResend(true);
            })
            .catch((err) => {
            })
    }

    function checkLinkIsValid() { 
        let linkEmail = params.get('user') ;
        const invEmail:string = linkEmail ? linkEmail : ""
        SignUp.checkLink(encodeURIComponent(invEmail), params.get('token'))
            .then((res: any) => {
                setIsVerifiedSuccessful(true);
            })
            .catch((err) => {
                if(err.includes("Email is already verified.")) {
                    setIsVerifiedSuccessful(true)
                } else {
                    setInvalidLink(true);
                }
            })
    }

    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////

    return (
        <div className="cards-main-holder column align-items-center thank-you-div" style={{display: "flex", justifyContent:"center", height: "100%"}}>
            <div className="card-background border-radius-s shadow-m display-box align-items-center position-relative">
                {getHeader()}
                {!showThankYouMsg && <div>
                    {!isVerifiedSuccessful && !invalidLink && getBeforeVerifiedContent()}
                    {isVerifiedSuccessful && getVerifiedContent()}
                    {!isVerifiedSuccessful && invalidLink && getInvalidLinkContent()}
                </div>}
                
                {showThankYouMsg && displayThankYouMsg()}
            </div>
        </div>
    )
}