import { ApiConstants } from "../Constants/ApiConstants";
import http from "./Interceptor";

export default class ResetPassword {
    static getCode(email) {
        return http.post(`${ApiConstants.IAM.GET_FORGOT_PASSWORD_CODE}`, email).then(
            (res) => {
                return Promise.resolve(res);
            },
            (err) => {
                return Promise.reject(err);
            }
        )
    }

    static resetPassword(data) {
        return http.post(ApiConstants.IAM.RESET_PASSWORD, data).then(
            (res) => {
                return Promise.resolve(res);
            },
            (err) => {
                return Promise.reject(err);
            }
        )
    }
}