import { useEffect, useState } from "react";
import {
    DKLabel,
    DKInput,
    INPUT_TYPE,
    INPUT_VIEW_DIRECTION,
    DKCheckMark,
    DKButton,
    showLoader
} from "deskera-ui-library";

import ResetPassword from "../../Services/ResetPassword";
import ReactRecaptcha3 from 'react-google-recaptcha3';
import ApiManager from '../../Managers/ApiManager';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';
import { isPasswordLengthValid } from "../../Utility/Utility";

export default function SetPasswordForm(props: any) {

    const urlParams = new URLSearchParams(window.location.search);

    const [formValues, setFormValues] = useState({} as any);
    const [isAddClient, setIsAddClient] = useState(true);
    const [isLoginTapped, setIsLoginTapped] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [validatePwd, setValidatePwd] = useState(false);

    useEffect(() => {
        RouteManager.setPresenter({props});

        setFormValues({
            ...formValues,
            confirmationCode: urlParams.get("token"),
            userName: urlParams.get("email"),
            orgName: urlParams.get("orgname"),
            email: urlParams.get("email"),
            fName: urlParams.get("firstname") !== null ? urlParams.get("firstname") : "",
            lName: urlParams.get("lastname") !== null ? urlParams.get("lastname") : ""
        })
        setIsAddClient(urlParams.get("source") === "addClient" ? true : false)

        ReactRecaptcha3.init(process.env.REACT_APP_reCAPTCHA_SITE_KEY).then(
            (status) => {
              console.log(status);
            }
        );
    }, [])

    ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    const getForm = () => {

        let title = formValues.orgName + (isAddClient ? " has already created your Deskera Books account for you " : " wants you to join their team on Deskera Book-Keeper");
        let subTitle = isAddClient ? "To start using Deskera Books account, generate your password" : "Generate your password";

        return (
            <div className="column display-box">
                <DKLabel text={title} className="fw-m fs-xxl mt-xl mb-m" />
                <DKLabel text={subTitle} className="fw-b fs-m mt-xl mb-l" />
                {isAddClient &&
                    <DKInput
                        name="orgName"
                        title="Organization Name"
                        value={formValues.orgName}
                        type={INPUT_TYPE.INPUT}
                        readOnly={true}
                        className="mt-m mb-m"
                        required={false}
                        direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    />
                }
                {!isAddClient &&
                    <DKInput
                        name="fName"
                        title="First Name"
                        value={formValues.fName}
                        type={INPUT_TYPE.INPUT}
                        className="mt-s mb-m"
                        readOnly={true}
                        required={false}
                        direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    />
                }
                {!isAddClient &&
                    <DKInput
                        name="lName"
                        title="Last Name"
                        value={formValues.lName}
                        type={INPUT_TYPE.INPUT}
                        className="mt-s mb-m"
                        readOnly={true}
                        required={false}
                        direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    />
                }
                <DKInput
                    name="email"
                    title="Email"
                    value={formValues.email}
                    type={INPUT_TYPE.EMAIL}
                    className="mt-m mb-m"
                    readOnly={true}
                    required={false}
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                />
                <DKInput
                    name="password"
                    title="Password"
                    value={formValues.password}
                    type={INPUT_TYPE.PASSWORD}
                    onChange={(value: any) => {
                        setFormValues({...formValues, password: value})}
                    }
                    canValidate={isLoginTapped}
                    required={true}
                    errorMessage={validatePwd && "Password length must be a min 8 characters and max 72 characters"}
                    className="mt-m mb-l"
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    validator={(text) => {
                        if(!isPasswordLengthValid(text) && text.length>0){
                            setValidatePwd(true);
                        } else {
                            setValidatePwd(false);
                        }
                        return isPasswordLengthValid(text);
                    }}
                />
                <DKInput
                    name="cpassword"
                    title="Confirm Password"
                    value={formValues.cPassword}
                    type={INPUT_TYPE.PASSWORD}
                    onChange={(value: any) => {
                        setFormValues({...formValues, cPassword: value})}
                    }
                    canValidate={isLoginTapped}
                    required={true}
                    errorMessage={validatePwd && "Password length must be a min 8 characters and max 72 characters"}
                    className="mt-m mb-l"
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                    validator={(text) => {
                        if(!isPasswordLengthValid(text) && text.length>0){
                            setValidatePwd(true);
                        } else {
                            setValidatePwd(false);
                        }
                        return isPasswordLengthValid(text);
                    }}
                />
                <div style={{display: "flex", flexWrap: "wrap"}} className="mt-s mb-m">
                    <DKCheckMark
                        title="I agree to the "
                        isSelected={formValues.agreeToTerms}
                        color="bg-blue"
                        onClick={() => {
                            setFormValues({...formValues, agreeToTerms: !formValues.agreeToTerms})
                        }}
                        className="pt-xs"
                    />
                    <DKButton
                        title={'Terms of Use'}
                        className="fw-h fs-m text-red"
                        style={{padding: "0px", fontSize:"13px", marginTop: "2px"}}
                        onClick={()=> window.open("https://www.deskera.com/terms-of-service", "_blank")}
                    />
                    <DKLabel text=" and " className="mt-xs"/>
                    <DKButton
                        title={'Privacy Policy'}
                        className="fw-h fs-m text-red"
                        style={{padding: "0px", fontSize:"13px", marginTop: "2px"}}
                        onClick={()=> window.open("https://www.deskera.com/privacy-policy", "_blank")}
                    />
                </div>
                <DKButton
                    title={loading ? "Please wait" : "Login"}
                    className="fs-m fw-m mr-r mt-m pt-m pb-m text-white"
                    style={{backgroundColor:"black", width: "100%", justifyContent: "center"}}
                    onClick={() => {
                        setIsLoginTapped(true);
                        login();
                    }}
                    disabled={loading || !formValues.agreeToTerms ? true: false}
                />
                <DKButton
                    title="Cancel"
                    style={{width: "100%"}}
                    className="mt-m border-m bg-white justify-content-center"
                    onClick={() => {
                        RouteManager.navigateToPage(PAGE_ROUTES.SIGN_IN, null)
                    }}
                />
                <div style={{width: "100%"}}>
                    {errorMsg.length > 0 &&
                        <div className={`text-red border-red p-v-s p-h-r border-radius-s text-wrap-none mt-m`} style={{whiteSpace: "break-spaces"}}>
                            {errorMsg}
                        </div>
                    }
                </div>
            </div>
        )
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    function login() {
        setLoading(true);
        if(validateValues()){
            let data = {
                confirmationCode: formValues.confirmationCode,
                password: formValues.password,
                userName: formValues.email
            }
            ResetPassword.resetPassword(data)
                .then((res: any) => {
                    setErrorMsg(res?.message)
                    if(res?.retriesLeft === -1) {
                        
                        signIn();
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    setErrorMsg("Invalid Login. Please contact administrator.")
                })
        } else {
            setLoading(false);
        }
    }

    function validateValues() {
        if (isPasswordLengthValid(formValues.password)) {
            if(formValues.password !== formValues.cPassword) {
                setErrorMsg("Confirm Password and Password does not match. Please try again.")
                return false;
            } else {
                setErrorMsg("");
                return true;
            }
        } else {
            return false;
        }
        
    }

    function signIn() {
        let data = {
            userName: formValues.email,
            password: formValues.password
        }

        ReactRecaptcha3.getToken().then(
            (token) =>{
                ApiManager.signIn(
                    data,
                    token,
                    (data) => {
                        showLoader("Logging In");
                        setLoading(false);
                        window.open(
                            process.env.REACT_APP_ALL_IN_ONE,
                            "_self"
                        );
                    },
                    (error) => {
                        setLoading(false);
                    }
                )
            }
        )
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <div className="cards-main-holder column align-items-center" style={{display: "flex", justifyContent:"center", height: "100%"}}>
            <div className="card-background card-div border-radius-s shadow-m display-box align-items-center position-relative">
                {getForm()}
            </div>
        </div>
    )
}