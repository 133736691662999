import {
    DKLabel,
    DKInput,
    INPUT_TYPE,
    DKButton,
    DKIcon
} from "deskera-ui-library";
import Utility from "../../Utility/Utility";
import { useState } from "react";

import ic_error from '../../Assets/ic_error.png';

export default function PersonaliseAccountForm(props: any) {

    const [formValues, setFormValues] = useState({
        industryType: "",
        manageType: "",
        companySize: ""
    })
    const [companySize1, setIsCompanySize1] = useState(true);
    const [companySize2, setIsCompanySize2] = useState(false);
    const [companySize3, setIsCompanySize3] = useState(false);
    const [companySize4, setIsCompanySize4] = useState(false);
    const [companySize5, setIsCompanySize5] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////
    
    const getHeader = () => <div className="column display-box">
        <DKLabel text= "Personalise your account" className="fw-m fs-xxl mt-xl mb-m" />
        <DKLabel text="We use this information to make your experience better" className="fw-b fs-m mt-xl mb-m" />
    </div>

    const getForm = () => <div className="column display-box">
        <DKInput
            placeholder="What is your industry type?"
            required={false}
            value={formValues.industryType}
            formatter={(obj: any) => {
                return obj;
            }}
            type={INPUT_TYPE.DROPDOWN}
            onChange={(value: any) => {
                setFormValues({
                    ...formValues, industryType: value.name
                })
            }}
            className="mt-m"
            dropdownConfig={{
                className: '',
                style: {width:"100px"},
                allowSearch: true,
                searchableKey: 'name',
                data: Utility.getIndustryTypes(),
                renderer: (index: any, obj: any) => {
                    return (
                        <div className="flex flex-row w-full justify-content-between" style={{width:"80px"}}>
                            <DKLabel
                                style={{ fontSize: '13px' }}
                                className="text-base border-radius-s"
                                text={obj.name}
                            />
                        </div>
                    );
                },
                onSelect: (index: any, value: any) => {}
            }}
        />
        <DKLabel text="What is your company size?" className="fw-b fs-m mt-xl mb-m" />
        <div style={{display: "flex", width: "100%", flexWrap: "wrap"}} className="company-size-buttons">
            <DKButton title="1-10" className={"border-m company-size-individual-button " + (companySize1 ? "bg-chip-blue" : "bg-gray2")} style={{justifyContent:"center"}} onClick={(val) => getCompanySize(val)}/>
            <DKButton title="11-25" className={"border-m company-size-individual-button "+ (companySize2 ? "bg-chip-blue" : "bg-gray2")} style={{justifyContent:"center"}} onClick={(val) => getCompanySize(val)}/>
            <DKButton title="26-50" className={"border-m company-size-individual-button "+ (companySize3 ? "bg-chip-blue" : "bg-gray2")}  style={{justifyContent:"center"}} onClick={(val) => getCompanySize(val)}/>
            <DKButton title="51-100" className={"border-m company-size-individual-button "+ (companySize4 ? "bg-chip-blue" : "bg-gray2")}  style={{justifyContent:"center"}} onClick={(val) => getCompanySize(val)}/>
            <DKButton title="100+" className={"border-m company-size-individual-button "+ (companySize5 ? "bg-chip-blue" : "bg-gray2")}  style={{justifyContent:"center"}} onClick={(val) => getCompanySize(val)}/>
        </div>
        <DKInput
            placeholder="What is the main thing you want to manage using Deskera app..."
            required={false}
            value={formValues.manageType}
            formatter={(obj: any) => {
                return obj;
            }}
            type={INPUT_TYPE.DROPDOWN}
            onChange={(value: any) => {
                setFormValues({
                    ...formValues, manageType: value.name
                })
            }}
            className="mt-m"
            dropdownConfig={{
                className: '',
                style: {width:"100px"},
                allowSearch: true,
                searchableKey: 'name',
                data: Utility.getManageType(),
                renderer: (index: any, obj: any) => {
                    return (
                        <div className="flex flex-row w-full justify-content-between">
                            <DKLabel
                                style={{ fontSize: '13px' }}
                                className="text-base border-radius-s"
                                text={obj.name}
                            />
                        </div>
                    );
                },
                onSelect: (index: any, value: any) => {}
            }}
        />
        <div style={{width: "100%"}} className="mt-m">
            {errorMsg.trim().length>0 && 
                <div style={{display: "flex"}}>
                    <DKIcon src={ic_error} style={{height: "19px"}}/>
                    <div className={`text-red text-wrap-none fw-m pl-s`} style={{whiteSpace: "break-spaces", fontSize: "12px", paddingTop:"2px"}}>
                        {errorMsg}
                    </div>
                </div>
            }
        </div>
        <DKButton
            title="Next"
            className="fs-m fw-m mr-r mt-l pt-m pb-m text-white"
            style={{backgroundColor: "black", width: "100%", justifyContent: "center"}}
            onClick={() => {
                if(formValues.industryType.length > 0 && formValues.manageType.length > 0){
                    let data = {
                        industryType: formValues.industryType,
                        companySize: getSelectedCompanySize(),
                        manageType: formValues.manageType
                    }
                    setErrorMsg("");
                    props.submitForm(data)
                } else {
                    setErrorMsg("Please select the other inputs");
                }
            }}
        />
        <DKButton
            title="Skip"
            style={{width: "100%"}}
            className="mt-m border-m bg-white justify-content-center"
            onClick={() => {
                props.submitForm();
            }}
        />
    </div>

    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////

    function getCompanySize(data) {
        setFormValues({...formValues, companySize: data.target.innerText })
        switch (data.target.innerText) {
            case "1-10":
                setIsCompanySize1(true);
                setIsCompanySize2(false);
                setIsCompanySize3(false);
                setIsCompanySize4(false);
                setIsCompanySize5(false);
                return;
            case "11-25":
                setIsCompanySize1(false);
                setIsCompanySize2(true);
                setIsCompanySize3(false);
                setIsCompanySize4(false);
                setIsCompanySize5(false);
                return;
            case "26-50":
                setIsCompanySize1(false);
                setIsCompanySize2(false);
                setIsCompanySize3(true);
                setIsCompanySize4(false);
                setIsCompanySize5(false);
                return;
            case "51-100":
                setIsCompanySize1(false);
                setIsCompanySize2(false);
                setIsCompanySize3(false);
                setIsCompanySize4(true);
                setIsCompanySize5(false);
                return;
            case "100+":
                setIsCompanySize1(false);
                setIsCompanySize2(false);
                setIsCompanySize3(false);
                setIsCompanySize4(false);
                setIsCompanySize5(true);
                return;
            default:
                setIsCompanySize1(false);
                setIsCompanySize2(false);
                setIsCompanySize3(false);
                setIsCompanySize4(false);
                setIsCompanySize5(true);
                return;
        }
    }

    function getSelectedCompanySize() {
        if(companySize1){
            return "1-10"
        } else if(companySize2) {
            return "11-25"
        } else if(companySize3) {
            return "26-50"
        } else if (companySize4) {
            return "51-100"
        } else if(companySize5) {
            return "100+"
        } else {
            return "1-10"
        }
    }

    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////

    return (
        <div>
            {getHeader()}
            {getForm()}
        </div>
    )
}